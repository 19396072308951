// Color system
$primary: #193b8d !default;
$secondary: #61677A !default;
$white: #fff;
$gray-100: #f1f1f1;
$gray-200: #e2e2e2;
$gray-300: #d7d7d7;
$gray-400: #ced6e0;
$gray-500: #efefef;
$gray-600: #dfe4ea;
$gray-700: #e5e7ef;
$gray-800: #29363d;
$gray-900: #61677a;
$text-gray: #444444;
$gray: #a6a6a6;
$black: #000 !default;

$blue: #0984e3;
$pink: #ff7675 !default;
$darkblue: #116ddc;
$lightblue: #57b7e9;
$selectedblue: #6fb0f9;
$red: #c23616;
$orange: #f0933e;
$yellow: #ffb713 !default;
$green: #7ed321;
$gold: #bcb241;

$colors: (
  blue: $blue,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
);

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $green,
  info: $orange,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  blue: $blue,
  dark: $gray-800,
  darkgray: $gray-900,
  gray: $gray-400,
  lightgray: $gray-500,
  darkblue: $darkblue,
);
$table-variants: (
  "light": $white,
);
.btn-primary {
  color: #fff;
}

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
);
