.cursor-pointer {
  cursor: pointer;
}

.font-xxs {
  font-size: 0.65rem !important;
}
.font-xs {
  font-size: 0.75rem !important;
}

.font-sm {
  font-size: 0.85rem !important;
}
.fw-medium {
  font-weight: 500;
}
.fw-semi-bold {
  font-weight: 600;
}
small {
  font-size: 80%;
}
.text-gray {
  color: $text-gray !important;
}
a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:not([href]):not([tabindex]) {
  text-decoration: none;

  @include hover-focus {
    color: inherit;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}
.calendar-wrap {
  position: relative;
  .form-control {
    padding-left: 30px;
  }
  .calendar-icon {
    position: absolute;
    left: 10px;
    top: 8px;
    @media (max-width: 767.98px) {
      left: 20px;
    }
  }
  .calendar-close-icon {
    position: absolute;
    right: 22px;
    top: 8px;
    z-index: 1;
  }
}

.pagination {
  .page-item {
    margin: 0 3px;
  }
  &.pagination-sm {
    .page-link {
      padding: $pagination-padding-y-sm $pagination-padding-x-sm;
      font-size: $pagination-font-size;
    }
  }
}

// button
.btn-transparent {
  color: $btn-transparent-color;
  background-color: transparent;
  border-color: transparent;
  font-size: $btn-font-size;
  &:hover {
    color: $btn-transparent-hover-color;
  }
  &:focus {
    box-shadow: none;
  }
}
.btn-gray {
  border-color: $border-color;
  background-color: $gray;
  &.btn-lg {
    padding: 0.8rem 1rem;
  }
  &:focus,
  &:active {
    box-shadow: none;
  }
}
.form-select {
  font-size: $form-select-font-size;
}
.action-btn {
  margin-bottom: 28px;
  .btn-sm {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
}

.show-overlay {
  .overlay {
    display: block;
  }
}

.card {
  border-radius: $card-border-radius;
  border-width: $card-border-width;
}

//flags
.flag-approval {
  color: $flag-approval;
}
.flag-progress {
  color: $flag-progress;
}
.flag-completed {
  color: $flag-completed;
}

/*Download Logo*/
.img-preview-wrap {
  flex-direction: column;
  align-items: stretch;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 10px;
  flex-wrap: wrap;
  a:hover > .middle {
    opacity: 1;
  }
}

// tabs
.nav {
  &.nav-tabs {
    background: none;
    border-bottom: 0;
    box-shadow: none;
    & + .tab-content {
      background: #ffffff;
      margin-bottom: 30px;
      box-shadow: none;
    }
    & > li {
      display: flex;
      position: relative;
      margin-right: 5px;
      margin-bottom: -1px;
      > a {
        background-color: $tabs-wrap-bg;
        border-radius: 0;
        border: none;
        color: #959595 !important;
        cursor: pointer;
        line-height: 50px;
        padding: 0 25px 0 20px;
        letter-spacing: 0.03em;
        font-weight: 400;
        margin-bottom: 5px;
        &:hover {
          color: #444444 !important;
        }
        &.active {
          color: $text-gray !important;
          font-weight: 600;
          padding-bottom: 5px;
          margin-bottom: -1px;
        }
      }
      & .active {
        & a {
          color: $text-gray !important;
          font-weight: 600;
          padding-bottom: 5px;
          margin-bottom: -1px;
        }
      }
    }
    &.nav-justified {
      & > .active {
        & > a {
          border: none;
          &:hover,
          &:focus {
            border: none;
          }
        }
      }
    }
  }
  & > li {
    & > a {
      position: relative;
      display: block;
      padding: 10px 15px;
    }
    &.active {
      & > a {
        background-color: #ffffff;
        border: 1px solid #ddd;
        border-bottom: 0;
        box-shadow: none !important;
        &:hover {
          box-shadow: none !important;
        }
      }
    }
  }
}

// File
//
// Custom file input.

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $custom-file-height;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: $custom-file-height;
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: $custom-file-height;
  padding: $custom-file-padding-y $custom-file-padding-x;
  line-height: 1.5;
  color: $custom-file-color;
  background-color: $custom-file-bg;
  border: $custom-file-border-width solid $custom-file-border-color;
  @include border-radius($custom-file-border-radius);
}

.w-150px {
  width: 150px;
}

//file upload

input[type="file"] {
  &::file-selector-button {
    display: none;
  }
  & + span {
    position: absolute;
    right: 0;
    pointer-events: none;
    z-index: 3;
    font-size: 0.875rem;
  }
}
.form-control {
  font-size: 0.875rem;
}

.single-note-wrap {
  .user-icon {
    color: lighten($primary, 20%);
  }
  .single-note {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 30px;
    &::before {
      content: "";
      position: absolute;
      left: 4px;
      bottom: 0;
      width: 1px;
      height: calc(100% - 20px);
      background-color: lighten($primary, 20%);
    }
    &::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: 0;
      bottom: calc(100% - 20px);
      background-color: lighten($primary, 20%);
    }
  }
}
.input-group{
  input{
    border-top-right-radius: 0.3rem!important;
    border-bottom-right-radius: 0.3rem!important;
  }
  .input-group-text{
    border-bottom-left-radius: .3rem!important;
    border-top-left-radius: .3rem!important;
  }
}
.custom-file-upload {
  position: relative;
  padding: 10px;
  border: 2px dashed #ddd;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 300px;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  app-icon {
    color: #e1e5e8;
    font-size: 4rem;
  }
}


// order-edit-tab

.order-edit-tab {
  position: relative;
  z-index: 2;

  .px-3.pt-4.border {
    border: none!important;
    padding: 0 !important;
  }
  .nav {
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 100%;
    border-bottom: 0;
    border: none !important;
    min-width: 150px;
    right: 0;
    left: auto;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

  

    li {
      width: auto;
      border: none;
      display: block;
      flex: 1 1 auto;
      text-align: center;
      min-width: 50px;
      margin-right: 5px;
      margin-bottom: -1px;
      max-width: 400px;
      &:last-child {
        border-right: 0 none;
        margin-right: 0;
      }
      a {
        background-color: #e6e6e6;
        border-radius: 0;
        border: none;
        color: #505461 !important;
        cursor: pointer;
        line-height: 40px;
        padding: 0 5px;
        letter-spacing: 0.03em;
        font-weight: 600;
        display: block;
        font-size: 12px;
        margin-bottom: 5px;
        &:hover {
          text-decoration: none;
        }
        .dropdown-toggle-split {
          color: #505461;
          font-weight: 600;
        }
      }
      & a.active {
        background-color: #ffffff;
        border: 1px solid #ddd;
        border-bottom: 0;
        margin-bottom: 1px;
        padding-bottom: 5px;
      }
      .with-submenu {
        &:hover {
          text-decoration: none;
          background-color: #ffffff;
          border: 1px solid #ddd;
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 5px;
        }
      }
    }
  }
}

.tab-content{
  .tab-pane{
    padding: 1.3rem;
  }
}

.cross{
  right: 11px;
  top: 0;
}

.modal-popup-open{
  overflow: hidden;
}
.table-wrapper-scroll-y {
  display: block;
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.logo-nav {
  padding: 0;
  list-style-type: none;
  li {
    display: inline-block;
    padding-right: 2px;
  }
  .h6 {
    font-size: 12px;
  }
}
.stitch-table {
  font-size: 12px;
}
.text-muted {
  color: #524d4d !important;
}
.check-lg{
  font-size: 23px !important;
    color: #7ed321 !important;
    line-height: 20px;
}
::ng-deep {
  .patch-color {
    .ng-select-container {
      background-color: #f8f8f8;
    }
    .ng-placeholder {
      color: #3e515b !important;
    }
  }
  .thread-color-holder {
    margin-top: 6px;
    float: left;
    padding: 10px;
    margin-right: 10px;
  }
}
.float-left {
  float: left;
}
.img-preview-wrap {
  align-items: center;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}
.toast-container {
  position: fixed;
}
