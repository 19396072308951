::ng-deep {
  .nav-tabs {
    border-top: 1px solid #e1e2e1;
    border-left: 1px solid #e1e2e1;
    border-right: 1px solid #e1e2e1;
    .nav-link {
      border: none;
      color: $tabs-nav-link;
      font-size: 14px;
      .flag-icon {
        margin-right: 10px;
        font-size: 16px;
        vertical-align: middle;
      }
      span {
        font-size: 25px;
        vertical-align: middle;
      }
    }
  }
  .tab-content {
    margin-top: -1px;
    background: #fff;
    border: 1px solid #ddd;
    .tab-pane {
      padding: 1rem;
    }
  }
  
}
