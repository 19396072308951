@import "./../bootstrap-variables";

// Core Admin Variables

$body-color:                                $text-gray;
$body-bg:                                   #f8f8f8;
$font-size-base:                            0.875rem;
$border-color:                              $gray-200 ;
$layout-transition-speed:                   0.25s !default;

// Navbar

$navbar-height:                             60px !default;
$navbar-bg:                                 #2c3f70;
$navbar-hamburger-color:                    $white !default;
$navbar-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;
$navbar-brand-width:                        155px !default;
$navbar-brand-logo:                         url("/assets/images/logo.svg") !default;

// Sidebar

$sidebar-width:                             270px !default;
$sidebar-padding:                           0 !default;
$sidebar-color:                             #fff !default;
$sidebar-bg:                                #f8f8f8 !default;
$sidebar-borders:                           none !default;
$mobile-sidebar-width:                      220px !default;

// Sidebar Navigation

$sidebar-nav-title-padding-y:               0.75rem !default;
$sidebar-nav-title-padding-x:               1rem !default;
$sidebar-nav-title-color:                   $gray-200;
$sidebar-nav-link-padding-y:                0.65rem !default;
$sidebar-nav-sub-link-padding-y:            0.25rem !default;
$sidebar-nav-link-padding-x:                0.5rem !default;
$sidebar-nav-link-color:                    $text-gray;
$sidebar-nav-link-icon-color:               #999da6 !default;

$sidebar-nav-link-hover-color:              $primary !default;
$sidebar-nav-link-hover-icon-color:         #999da6 !default;

$sidebar-nav-badge-color:                   $primary !default;

$sidebar-nav-link-active-color:             $primary !default;
$sidebar-nav-link-active-icon-color:        #999da6 !default;

// Top Navigation

$top-nav-bg:                                #2c3f70 !default;

// Aside

$aside-menu-width:                          500px !default;
$aside-menu-nav-padding-y:                  0.75rem !default;

//menu height

$horizontal-menu-height:                    51px;
$fixed-bottom-menu-height:                  52px;

//badge

$badge-padding-x:                           0.65em;
$badge-border-radius:                       10px;
$menu-icon-color:                           #555555;

// Breadcrumb

$breadcrumb-color:                          $text-gray;
$breadcrumb-divider-color:                  $breadcrumb-color;

//link color
$link-decoration:                           none;

//table coloe

$table-header-bg:                           $gray-700;
$table-text-color:                          $text-gray;
$table-border-color:                        $gray-200;

//pagination

$pagination-padding-y-sm:                   0.13rem;
$pagination-padding-x-sm:                   0.5rem;
$pagination-font-size:                      12px;

//button

$btn-transparent-color:                     $black;
$btn-transparent-hover-color:               $primary;
$btn-padding-x-sm:                          1rem;
$btn-font-size:                             $font-size-base;

//select

$form-select-indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-contract' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M3.646 13.854a.5.5 0 0 0 .708 0L8 10.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zm0-11.708a.5.5 0 0 1 .708 0L8 5.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
$form-select-bg-size:                       16px 18px;
$form-select-font-size:                     $font-size-base;

//color label

$label-yellow:                              #f4ea63;
$label-blue:                                #6c94c9;
$label-gold:                                #ee9e23;
$label-jupiter:                             #267dc4;   

//label count

$label-pending:                              $pink;
$label-progress:                             $blue;
$label-completed:                            $green;

//card

$card-border-radius:                         0;
$card-border-width:                          0;

//tabs
$tabs-wrap-bg:                               $gray-500;
$tabs-nav-link:                              $text-gray;

//flags
$flag-approval:                              $pink;
$flag-progress:                              $blue;
$flag-completed:                             $gold;


// statusbar
$status-bar-color:                          #4DA9E6 !default;

$virtual-prd-link:                          #4DC0E6;

// custom file upload
$custom-file-height:                        calc(1.5em + .75rem + 2px);
$custom-file-padding-y:                     .375rem ;
$custom-file-padding-x:                      .75rem; 
$custom-file-color:                          #3e515b;
$custom-file-bg:                             #f8f8f8;
$custom-file-border-width:                    1px;
$custom-file-border-color:                    $gray-200;
$custom-file-border-radius:                   .25rem;

