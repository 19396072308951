@use "sass:math";
// IE10&11 Flexbox fix
@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

body {
  color: $body-color;
  font-size: $font-size-base;
  background-color: $body-bg;
}
.app,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  flex: 0 0 $navbar-height;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
  }

  .sidebar {
    flex: 0 0 $sidebar-width;
    order: -1;
  }

  .aside-menu {
    flex: 0 0 $aside-menu-width;
  }
}

//
// header
//
.header-fixed {
  .app-header {
    position: fixed;
    z-index: $zindex-sticky;
    width: 100%;
    top: 0;
    box-shadow: 0px 0px 7px 0px #d2d0d0;
    background-color: $top-nav-bg;
    flex-wrap: nowrap;
  }
  .app-body {
    margin-top: $navbar-height;
  }
}

//
// Sidebar
//
.sidebar-hidden {
  .sidebar {
    margin-left: - $sidebar-width;
  }
}

.sidebar-fixed {
  .sidebar {
    position: fixed;
    width: $sidebar-width;
    height: 100%;
    top: $navbar-height;
    .sidebar-nav {
      height: calc(100vh - calc(#{$navbar-height}));
    }
  }
  .main,
  .app-footer {
    margin-left: $sidebar-width;
  }

  &.sidebar-hidden {
    .main,
    .app-footer {
      margin-left: 0;
    }
  }
}

.container-fluid {
  min-height: calc(
    100vh - 200px
  ); // set min height to keep copyright text always at bottom
}

//
// Aside Menu
//
.aside-menu-hidden {
  .aside-menu {
    margin-right: - $aside-menu-width;
  }
}

.aside-menu-fixed {
  .aside-menu {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 1022;
    overflow-y: auto;
  }

  &.aside-menu-hidden {
    .main,
    .app-footer {
      margin-right: 0;
    }
  }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left $layout-transition-speed,
    margin-right $layout-transition-speed, width $layout-transition-speed,
    flex $layout-transition-speed;
}

//
// Mobile layout
//

@include media-breakpoint-down(lg) {
  .main {
    margin-left: 0 !important;
  }
  .app-header {
    position: fixed !important;
    z-index: $zindex-sticky;
    width: 100%;
    text-align: center;
    justify-content: flex-start;
    .navbar-brand {
      position: absolute;
      left: 50%;
      margin-left: - math.div($navbar-brand-width, 2);
    }
  }
  .app-body {
    margin-top: $navbar-height;
  }
}

//card
.card {
margin-bottom: 1.5 * $spacer;
-webkit-box-shadow: none;
-moz-box-shadow: none;
box-shadow: none;
border-radius:0; 
 .card-header{
   font-size: 15px;
   padding: 11px 20px;
   &.bg-darkgray{
     color: white;
   }
   &:first-child{
     border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
   }
 }
}

//modal overlay

.cdk-overlay-container{
  z-index: 1024;
}